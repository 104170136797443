import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element.js';
import './components/main.scss';
import './assets/iconfont/iconfont.css';
import kit from './plugins/kit.js';
import i18n from './plugins/I18N';
import auth from './plugins/auth';
import dict from './plugins/dict';

import XEUtils from 'xe-utils';
import VXETable from 'vxe-table';
import VXETablePluginElement from 'vxe-table-plugin-element';
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx';
import 'vxe-table/lib/index.css';

Vue.mixin(i18n);
Vue.mixin(auth);
Vue.mixin(dict); 

import FilterContent from "./components/FilterContent.vue";
import FilterInput from "./components/FilterInput.vue";
import FilterComplex from "./components/FilterComplex.vue";

Vue.component(FilterContent.name, FilterContent);
Vue.component(FilterInput.name, FilterInput);
Vue.component(FilterComplex.name, FilterComplex);


// 创建一个支持列内容的筛选器
VXETable.renderer.add('FilterContent', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  // 筛选模板
  renderFilter (h, renderOpts, params) {
    return [
      <filter-content params={ params }></filter-content>
    ]
  },
  // 重置数据方法
  filterResetMethod ({ options }) {
    options.forEach(option => {
      option.data = { vals: [], sVal: '' }
    })
  },
  // 筛选数据方法
  filterMethod ({ option, row, column }) {
    const { vals } = option.data
    const cellValue = XEUtils.get(row, column.property)
    /* eslint-disable eqeqeq */
    return vals.some(val => val == cellValue || (val==null || (val=='null' && !cellValue)));
  }
});

VXETable.renderer.add('FilterInput', {
  // 筛选模板
  renderFilter (h, renderOpts, params) {
    return [
      <filter-input params={ params }></filter-input>
    ]
  },
  // 筛选方法
  filterMethod ({ option, row, column }) {
    const { data } = option
    const cellValue = XEUtils.get(row, column.property)
    /* eslint-disable eqeqeq */
    if(cellValue){
      if(data == '!' || data == '！'){
        return true;
      }
    }else{
      if(/\s+/.test(data)){
        return true;
      }
    }

    return cellValue? cellValue.indexOf(data) > -1 : false
  }
})

// 创建一个条件的渲染器
VXETable.renderer.add('FilterComplex', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  // 筛选模板
  renderFilter (h, renderOpts, params) {
    return [
      <filter-complex params={ params }></filter-complex>
    ]
  },
  // 重置数据方法
  filterResetMethod ({ options }) {
    options.forEach(option => {
      option.data = { type: 'has', isCase: true, name: '' }
    })
  },
  // 筛选数据方法
  filterMethod ({ option, row, column }) {
    let cellValue = XEUtils.get(row, column.property)
    const { type, isCase } = option.data
    let { name } = option.data;
    cellValue = cellValue ? cellValue : 0;
   
    if (isCase) {
      cellValue = cellValue+"".toLowerCase()
      name = name.toLowerCase()
    }

    if(!cellValue && /\s+/.test(name)){
      return true;
    }

    switch (type) {
      case 'has':
        return (cellValue+"").indexOf(name) > -1
      case 'eq':
        /* eslint-disable eqeqeq */
          return cellValue == name
        
      case 'gt':
        return cellValue > name*1
      case 'lt':
        return cellValue < name*1
    }
    return false
  }
})

VXETable.use(VXETablePluginElement)
VXETable.use(VXETablePluginExportXLSX)
Vue.use(VXETable);

Vue.config.productionTip = false
Vue.use(kit);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
