<template>
  <div>
  <el-container class="xcontainer" >
    <transition name="el-fade-in-linear">
      <el-aside v-show="isShowAside" class="xaside" style="width:auto;" :style="{background: background}">
        <div class="xlogo" style="padding-right:15px;">
          <!-- <div :class="isCollapse? 'logo-min-wrap' : 'logo-wrap'">
            <img :src="this.imgUrl+logoId" alt="logo" >
          </div> -->
          <el-collapse-transition>
            <div v-show="!isCollapse">{{msg(user.title?user.title:'陞航物流')}}</div>
          </el-collapse-transition>
        </div> 
        <el-menu 
            :collapse="isCollapse"
            :background-color="background"
            :text-color="textColor"
            :active-text-color="textActiveColor" 
            style="border:none;"
            @select="selectMenuItem"
            id="menu"
        > 
            <span  v-for="item in menus" :key="item.id">
              <el-menu-item v-if="!item.children || item.children.length==0" :index="item.id+''" :route="{name: 'about1'}">
                  <i v-if="item.icon" :class="['iconfont', item.icon]"></i>
                  <transition name="el-fade-in-linear">
                    <span v-show="!isCollapse">{{item.label}}</span>
                    <!--
                    <div style="display:flex; flex-direction:column; position:relative; top:-10px;">
                      <span v-show="!isCollapse" style="height:25px;">{{item.label}}</span>
                      <span v-show="!isCollapse" style="font-size:12px; color:#BDC8D4; height:25px;">{{item.label}}</span>
                    </div>
                    -->
                  </transition>
              </el-menu-item>
              <el-submenu  v-else :index="item.id+''">
                <template slot="title">
                  <i v-if="item.icon" :class="['iconfont', item.icon]"></i> 
                  <transition name="el-fade-in-linear">
                    <span v-show="!isCollapse">{{item.label}}</span>
                    <!--
                    <div style="display:flex; flex-direction:column; position:relative; top:-10px;">
                      <span v-show="!isCollapse" style="height:25px;">{{item.label}}</span>
                      <span v-show="!isCollapse" style="font-size:12px; color:#BDC8D4; height:25px;">{{item.label}}</span>
                    </div>
                    -->
                  </transition>
                </template> 
                <el-menu-item :index="sub.id+''" v-for="sub in item.children" :route="{name: 'about1'}" :key="sub.id">
                  <i v-if="sub.icon" style="margin-right:8px;" :class="['iconfont', sub.icon]"></i><span>{{sub.label}}</span>
                </el-menu-item>
              </el-submenu>
            </span>
        </el-menu>
      </el-aside>
    </transition>
    <el-container>
      <el-header  style="height:auto; padding: 0px;">
        <el-row class="xheader" style="padding: 8px 15px">
          <el-col :xl="24" style="display:flex; justify-content: space-between; align-items:center; ">
            <div class="header-left" style="white-space:nowrap;">
              <span v-show="isShowAside && !isCollapse" class="el-icon-s-fold left-expand-icon" @click="asideCollapse()"></span>
              <span v-show="isShowAside && isCollapse" class="el-icon-s-unfold left-expand-icon" @click="asideCollapse()"></span>
              <span v-show="isShowAside" class="el-icon-d-arrow-left left-expand-icon" @click="asideShow()"></span>
              <span v-show="!isShowAside" class="el-icon-d-arrow-right left-expand-icon" @click="asideShow()"></span>
              <span class="header-title" style="display:inline-block;position:relative; top:-3px; margin-left:15px; font-size:20px;">{{msg('欢迎登录')+(user?"":user.fullname)}}</span>
            </div>
            <div class="xtool">
                <el-dropdown @command="clickDropItem">
                  <div class="photo-wrap">
                    <img src="../assets/img/user1.png" />
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="fullname">{{user.fullname}}</el-dropdown-item>
                    <el-dropdown-item divided  icon="el-icon-tickets" command="info">{{msg('个人信息')}}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-edit-outline" command="pwd">{{msg('修改密码')}}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-error"  command="logout">{{msg('注销')}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-badge :value="total"  class="item"> 
                  <span class="el-icon-chat-dot-square" @click="getMessageData(false)"></span>
                </el-badge>
                <el-dropdown @command="switchLang">
                  <div :class="['iconfont', langIcon?langIcon:'']" style="margin-left:15px; font-size:24px;">
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="iconfont" divided  icon="icon-yuyan-zhong" command="zh-cn">{{msg('简体中文')}}</el-dropdown-item>
                    <el-dropdown-item class="iconfont" divided  icon="icon-lang-txt-zh-tw" command="zh-hk">{{msg('繁体中文')}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
            </div>
          </el-col>
        </el-row>
        
      </el-header>
      <el-main> 
        <transition name="el-fade-in-linear">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </el-main>
    </el-container>
  </el-container>

  <el-dialog :title="msg('修改密码')" :visible.sync="dialogPwdVisible" width="450px" @opened="opendChangePwd()" :append-to-body="true">
    <account-pwd v-on:closeDg="dialogPwdVisible = false" ref="changePwdForm"/>
  </el-dialog>
  
  <el-dialog :title="msg('面单上传提醒')" :visible.sync="dialogWarnVisible" width="800px" :append-to-body="true" :destroy-on-close="true">
    <warn-message v-on:closeDg="dialogWarnVisible = false" ref="warnForm" :warns="warns" :exp-errs="expErrs" :time-outs="timeOuts"/>
  </el-dialog>

  <el-dialog :title="msg('通知')" :visible.sync="noticeVisible" width="650px" :append-to-body="true" :destroy-on-close="true">
    <home-notice v-on:close-dg="noticeVisible = false" ref="noticeForm"/>
  </el-dialog>

</div>
</template>


<script>
// @ is an alias to /src
import AccountPwd from "./sys/AccountPwd.vue";
import WarnMessage from "./sys/WarnMessage.vue";
import HomeNotice from "./sys/HomeNotice.vue";
export default {
  name: 'home',
  components: {
    AccountPwd: AccountPwd,
    WarnMessage:WarnMessage,
    HomeNotice:HomeNotice
  },
  created(){
    var _this = this;
    this.user = JSON.parse(localStorage.getItem("user"));
    if(this.user== null) {
      this.$router.push("/login");
      return;
    }

    let rdUrl = this.$kit.getQueryValue("url");
    if(rdUrl == 'account'){
      this.$router.push("/admin/account");
    }else if(rdUrl == 'shop'){
      this.$router.push("/admin/shop");
    }

    this.$store.commit('docHeight', window.docHeight);
    if(window.docWidth<500){
      this.isCollapse = true;
      this.$store.commit('isMinScreen', true);
      this.$store.commit('isQueryExpend', true);
    }else{
      this.$store.commit('isMinScreen', false);
      this.$store.commit('isQueryExpend', false);
    }

    window.onresize = function(){
      var width = document.documentElement.clientWidth;
      _this.$store.commit('docHeight', document.documentElement.clientHeight);
      if(width<500){
        _this.isCollapse = true;
        _this.$store.commit('isMinScreen', true);
      } else {
        _this.isCollapse = false;
        _this.$store.commit('isMinScreen', false);
      }
    };

    this.axios.get(this.$kit.api.rolemenu).then(function (result) {
        if(result.status){

          for(var i=0; i<result.data.length; i++){
            var item = result.data[i];
            _this.menus.push(item);
          }

          _this.menus.sort((a, b)=>{
            return a.seq - b.seq;
          });

        }else{
          _this.$message(result.msg);
        }
      }).catch(function (error) {
        console.error(error);
    });

    this.langs["zh-cn"] = "icon-yuyan-zhong";
    this.langs["zh-hk"] = "icon-lang-txt-zh-tw";
    this.langs["en"] = "icon-yuyan-ying";

    var lang = localStorage.getItem("lang") || 'zh-cn';
    this.langIcon = this.langs[lang];
    
    window.document.title = this.msg("web-title");

    var company = this.$store.state.companyInfo;
    if(company){
      this.companyInfo = this.companyInfo;
    }else{
      this.axios.get(this.$kit.api.companyInfo).then((result) => {
        //console.log(result); 
        if(result.status){
          this.companyInfo=result.data;
          this.$store.commit('companyInfo', this.companyInfo);
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }
    this.axios.get(this.$kit.api.warnTotal).then(function (result) {
        if(result.status){
          _this.total = result.data;
        }
    });

    // setInterval(function(){
    //   if(_this.total > 0){
    //       _this.WarnMessage();
    //   }
    // }, 300000);

    // setInterval(function(){
    //   _this.getTotal();
    // }, 300000);

  },
  data(){
    return{
      menus: [],
      user: null,
      isCollapse: false,
      title: "欢迎",
      isShowAside: true,
      background: "#545c64",
      textColor: "#ffffff",
      textActiveColor: "#ffd04b",
      langIcon: null,
      langs: {},
      imgUrl: this.$kit.api.imgView,
      companyInfo: {},
      logoUrl: null,
      dialogPwdVisible: false,
      dialogWarnVisible:false,
      total:0,
      isFirst:true,
      noticeVisible:false,

      warns: [],
      expErrs: [],
      timeOuts: [],
    }
  }, 
  computed:{
      logoId: {
          get(){
            if(this.companyInfo['back-logo']){
              return this.companyInfo['back-logo'].text;
            }
            return "";
          }
      }, 
  },
  methods: {
    getTotal(){
      var _this = this;
      this.axios.get(this.$kit.api.warnTotal).then(function (result) {
          if(result.status){
            _this.total = result.data;
          }
      });
    },
    asideCollapse: function(){  //显示或者隐藏菜单朗
      //console.log(this.isCollapse);
      this.isCollapse = !this.isCollapse;
    },
    asideShow: function(){  //显示或者隐藏菜单朗
      //console.log(this.isCollapse);
      this.isShowAside = !this.isShowAside;
    },
    clickDropItem: function(command){
      if(command == 'logout'){
        this.axios.get(this.$kit.api.logout)
          .then((result)=>{
            if(result.data == null){
              this.$router.push("/login");
            }else{
              localStorage.setItem("user", JSON.stringify(result.data));
              window.location.reload(true);
            }
          })
        
      }else if(command == 'pwd'){
        this.dialogPwdVisible = true;
      }
    },
    switchLang: function(command){
      this.langIcon = this.langs[command];
      localStorage.setItem("lang", command);
      location.replace(location.href);
    },
    selectMenuItem: function(index, indexPath){
      //console.log(index, indexPath);
      var menu = this.findMenu(index*1, this.menus);
      //console.log(menu)
      if(menu){
        this.$router.push(menu.url);
      }
    },
    findMenu: function(id, menus){
      var m = null;
      for(var i=0; i<menus.length; i++){
        var menu = menus[i];
        if(menu.id == id){
          m = menu; 
          break;
        }
        else if(menu.children && menu.children.length>0){
          m = this.findMenu(id, menu.children);
          if(m!=null) 
            break;
        }
      }
      return m;
    },
    opendChangePwd(){
      this.$nextTick(() => {
        this.$refs.changePwdForm.initForm();
      });
    },
    WarnMessage(){
      this.getMessageData(true);
    },
    
    getMessageData(type){
        this.axios.get(this.$kit.api.warnList).then( (result)  => {
            if(result.status){
              var warnExps = result.data["exps"];
              var errs = result.data["expErrs"];
              var timeOuts = result.data['timeouts'];
              if(warnExps){
                this.warns = warnExps;
              }
              if(errs){
                this.expErrs = errs;
              }
              if(timeOuts){
                this.timeOuts = timeOuts;
              }

              if(type){
                var localExpWarns = localStorage.getItem("locExpWarns");
                var localExpErrs = localStorage.getItem("locErrExps");
                var localTimeouts = localStorage.getItem("locTimeouts");

                this.removeItem(this.warns, localExpWarns);
                this.removeItem(this.expErrs, localExpErrs);
                this.removeItem(this.timeOuts, localTimeouts);
              }
              var ctotal = this.warns.length + this.expErrs.length + this.timeOuts.length;
              if(ctotal > 0){
                this.dialogWarnVisible = true;
              }
            }
        });
      },

      removeItem(target, src){
        if(src && target){
          let temp = JSON.parse(src);
          for(var i=0; i<temp.length; i++){
            let t = temp[i];
            let index = target.findIndex(c => c.id == t.id);
            if(index>-1){
              target.splice(index,1);
            }
          }
        }

      }

  }
}
</script>
<style class="scss" scoped>
.xcontainer{height:100%; background:#EDF0F5;}
.xaside{ position:relative;  box-shadow: 8px 0px 10px rgba(0,0,0,0.15);} 

#menu .iconfont{font-size:24px; display:inline-block; margin-right:10px;}

.xheader{background:white; box-shadow: 0px 5px 10px rgba(0,0,0,0.05);}
.xlogo{display:flex; align-items: center; padding: 15px 0px 15px 15px; font-size:24px; color: white; box-sizing: border-box; width:100%;}
.logo-wrap{width:64px; height:64px; overflow:hidden;}
.logo-wrap img{width:64px; }

.logo-min-wrap{width:36px; height:36px; overflow:hidden;}
.logo-min-wrap img{width:36px; }

.xheader{padding: 8px 15px; border-bottom: 1px solid rgba(0,0,0,0.15); }
.xtool{display:flex; align-items: center; justify-content:flex-end; padding-right:25px;}
.xtool > *{padding-left:5px; padding-right: 5px; cursor:pointer;}
.xtool .photo-wrap{width:24px; height:24px; overflow:hidden; border-radius:50%;}
.xtool img{width:24px; }
.left-expand-icon{font-size:24px; cursor:pointer;}

</style>
