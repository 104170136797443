<template>
    <div>
      <div>
        <div style="text-align:center;margin-top: -40px;"><h3>发货快服务器停止运行通知</h3></div>
        <div style="width:90%;">
          <p>
            尊敬的各位用户：
          </p>
          <p>
            因发货快系统现有功能不再满足市场用户使用需求，并有功能更加齐全的顺运宝系统替代；
            团队决定在2020年11月18号后停运发货快所有服务，
            请各位用户在发货快系统停运前切换到顺运宝系统；
            登录账号与发货快一致，登录默认密码为：123456；
            登录后请第一时间在账户管理菜单中修改自己账户的原始密码；如登录遇到困难，请联系客服处理！
          </p>
          <p>
            为确保各位用户在新旧系统的顺利切换，现将发货快系统关闭进程事宜公布如下：
          </p>
          ***************************************************************************************************
          <p>2020/11/14号之前，团队将努力维持发货快现有功能正常运转；</p>
          <p>2020/11/15—2020/11/17号，关闭数据录入功能，但能登录进行查单服务；</p>
          <p>2020/11/18号，所有服务停止；</p>
          ***************************************************************************************************
          <p>对于关闭事项给大家带来的不便，深表歉意。顺祝生意兴隆，财源广进！</p>
           <p>顺运宝登录地址：<a href="https://106.12.132.86" target="_blank">https://106.12.132.86</a></p>
        </div>
      </div>
      <div style="text-align:center; margin: 15px 0px;">
          <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'AccountPwd',
  data(){
    return{
    }
    
  }, 
  methods:{
  }
}
</script>

<style class="scss" scoped>
p{text-indent: 2em}

</style>
