
var dict = {
    data(){
      return{
        dicts: {
            cssTypes: [
                'success',
                'danger',
                'primary',
                'info',
                'warning',
                'success',
                'success',
                'success',
                'success',
                'success',
                'success',
                'success',
            ],
            //是否允许上传面单
            allowUploadPages:[ this.msg('不允许'), this.msg('允许'), this.msg( '面单出货')], 
            allowUploadPages1: [ 
                {label: this.msg('不允许'), value: 0},
                {label: this.msg('允许'), value: 1},
                {label: this.msg('面单出货'), value: 2}
            ],
            //物流包裹类型
            expPkgTypes:[
                this.msg('转寄包'), this.msg( '客包'), this.msg( '仓包'),
                this.msg('现货包'), this.msg( '过期转寄包'), this.msg( '转寄单包'),
                this.msg('现货转寄包'), this.msg( '拆合转寄包')
            ],
            expPkgTypes1: [ 
                {label: '请选择', value: 0},
                {label: this.msg('客包'), value: 1},
                {label: this.msg('仓包'), value: 2},
                {label: this.msg('现货包'), value: 3},
                {label: this.msg('过期转寄包'), value: 4},
                {label: this.msg('转寄单包'), value: 5},
                {label: this.msg('现货转寄包'), value: 6},
                {label: this.msg('拆合转寄包'), value: 7},
            ],
            expPkgTypes2:{null : this.msg('空包'), 0 :'请选择', 1: this.msg( '客包'), 2: this.msg( '仓包'),3: this.msg( '现货包'),
                            4: this.msg( '过期转寄包'),5: this.msg( '转寄单包'),6: this.msg( '现货转寄包'),7: this.msg( '拆合转寄包')},
            //物流退件上架的状态
            expBackStatus:  [
                this.msg("待处理"), 
                this.msg("待转寄"), 
                this.msg("已转寄"), 
                this.msg("待寄回"), 
                this.msg("寄回打包中"), 
                this.msg("已寄回"), 
                this.msg("已弃件")
            ],
            expBackStatus1: [ 
                {label: this.msg('待处理'), value: 0},
                {label: this.msg('待转寄'), value: 1},
                {label: this.msg('已转寄'), value: 2},
                {label: this.msg('待寄回'), value: 3},
                {label: this.msg('寄回打包中'), value: 4},
                {label: this.msg('已寄回'), value: 5},
                {label: this.msg('已弃件'), value: 6},
            ],
            //仓储状态
            storeStatus:[
                this.msg("上架"), 
                this.msg("已转寄"), 
                this.msg("已寄出"), 
                this.msg("待寄回"), 
                this.msg("寄回打包中"), 
                this.msg("已寄回"), 
                this.msg("已弃件")
            ],
            storeStatus1:[
                {label: this.msg('上架'), value: 0},
                {label: this.msg('已转寄'), value: 1},
                {label: this.msg('已寄出'), value: 2},
                {label: this.msg('待寄回'), value: 3},
                {label: this.msg('寄回打包中'), value: 4},
                {label: this.msg('已寄回'), value: 5},
                {label: this.msg('已弃件'), value: 6},
            ],
            //物流状态
            expStatus: [
                this.msg("未打單"), 
                this.msg("已打單"), 
                this.msg("已发货"), 
                this.msg("装船"), 
                this.msg("清关"), 
                this.msg("提货"), 
                this.msg("取消"), 
                this.msg("台湾上架"), 
                this.msg("转配送"),
                this.msg("面单过期"),
                this.msg("已转寄"),
                this.msg("寄回大陆"),
                this.msg("已到齐")
            ],
            expStatus1: [
                {label: this.msg("未打單"), value: 0},
                {label: this.msg("已打單"), value: 1},
                {label: this.msg("已发货"), value: 2},
                {label: this.msg("装船"), value: 3},
                {label: this.msg("清关"), value: 4},
                {label: this.msg("提货"), value: 5},
                {label: this.msg("取消"), value: 6},
                {label: this.msg("台湾上架"), value: 7},
                {label: this.msg("转配送"), value: 8},
                {label: this.msg("面单过期"), value: 9},
                {label: this.msg("已转寄"), value: 10},
                {label: this.msg("寄回大陆"), value: 11},
                {label: this.msg("已到齐"), value: 12},
            ],
            //国外物流公司
            expCompanies:[
                {label: this.msg("7-11"), value: "7-11"},
                {label: this.msg("全家"), value: "全家"},
                {label: this.msg("OK"), value: "OK"},
                {label: this.msg("莱尔富"), value: "莱尔富"},
                {label:  this.msg("萊爾福"), value: "萊爾福"},
                {label:  this.msg("黑貓"), value: "黑貓"},
                {label:  this.msg("卖家宅配"), value: "卖家宅配"},
                {label:  this.msg("賣家宅配"), value: "賣家宅配"},
            ],
            expCompanies1:[
                {label: this.msg("7-11"), value: "7-11"},
                {label: this.msg("全家"), value: "全家"},
                {label: this.msg("OK"), value: "OK"},
                {label: this.msg("莱尔富"), value: "莱尔富"},
                {label:  this.msg("萊爾福"), value: "萊爾福"},
                {label:  this.msg("黑貓"), value: "黑貓"},
                {label:  this.msg("卖家宅配"), value: "卖家宅配"},
                {label:  this.msg("賣家宅配"), value: "賣家宅配"},
            ],
            //是否打包
            packageds: [
                {label: this.msg("有"), value: 1},
                {label: this.msg("无"), value: 2},
            ],
            storeErrStatus: [
                this.msg("未转寄"),
                this.msg("已转寄"),
            ],
            //是否需要仓库打包
            hasStorePkgs:[
                this.msg("需要仓库打包"), 
                this.msg("不需要仓库打包"), 
                this.msg("退件转寄打包"), 
            ],
            hasStorePkgs1: [
                {label: this.msg("需要仓库打包"), value: 0},
                {label: this.msg("否不需要仓库打包"), value: 1},
                {label: this.msg("退件转寄打包"), value: 2},
            ],
            //物流的面单状态
            expPageStatus: [
                this.msg("未上传"),
                this.msg("待打印"),
                this.msg("已打印"),
            ],
            expPageStatus1: [
                {label: this.msg("未上传"), value: 0},
                {label: this.msg("待打印"), value: 1},
                {label: this.msg("已打印"), value: 2},
            ],
            //订单来源
            expOrigins: [
                this.msg("店配"),
                this.msg("懒人"),
                this.msg("线下"),
                this.msg("线下已建单"), 
            ],
            expOrigins1: [
                {label: this.msg("店配"), value: 0},
                {label: this.msg("懒人"), value: 1},
                {label: this.msg("线下"), value: 2},
                {label: this.msg("线下已建单"), value: 3},
            ],
            expStoreStatus: [
                this.msg("未到货"),
                this.msg("已收货"),
            ],
            //面单来源平台
            expPlatforms:[
               this.msg("虾皮"),
               this.msg("露天"),
               this.msg("奇摩"),
               this.msg("淘宝"),
            ],
            expPlatforms1: [
                {label: this.msg("虾皮"), value: '0'},
                {label: this.msg("露天"), value: '1'},
                {label: this.msg("奇摩"), value: '2'},
                {label: this.msg("淘宝"), value: '3'},
                {label: this.msg("PC Home"), value: '4'},
                {label: this.msg("绿界"), value: '5'},
            ],
            //面单异常类型
            expErrTypes: [
                this.msg("面单异常"),
                this.msg("货物异常"),
                this.msg("物流异常"),
                this.msg("货物认领"),
            ],
            expErrTypes1: [
                {label: this.msg("面单异常"), value: 0},
                {label: this.msg("货物异常"), value: 1},
                {label: this.msg("物流异常"), value: 2},
                {label: this.msg("货物认领"), value: 3},
            ],
            //处理状态
            processes: [
                this.msg("未处理"),
                this.msg("已处理"),
            ],
            processes1: [
                {label: this.msg("未处理"), value: 0},
                {label: this.msg("已处理"), value: 1},
            ],
            //物流包裹状态
            expPkgStatus: [
                this.msg("已打包"),
                this.msg("已发货"),
                this.msg("已离境"),
                this.msg("已清关"),
                this.msg("已提货"),
            ],
            expPkgStatus1: [
                {label: this.msg("已打包"), value: 0},
                {label: this.msg("已发货"), value: 1},
                {label: this.msg("已离境"), value: 2},
                {label: this.msg("已清关"), value: 3},
                {label: this.msg("已提货"), value: 4},
            ],
            //转寄状态
            resendStatus: [
                this.msg("正常"), 
                this.msg("回滚")
            ],
            //转寄状态
            resendStatus1: [
                {label: this.msg("正常"), value: 0},
                {label: this.msg("回滚"), value: 1},
            ],
            hasInnExps: ["否","是"],
        }
      };
    },

    methods:{
        getDicts(key){
            console.log(this[key]);
            return this[key];
        }

    }

}

export default dict;
