"use strict";

import Vue from 'vue';
import axios from "axios";
import { Message, Loading } from "element-ui";
import router from "../router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = "application/json; charset=utf-8";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 180 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

// _axios.interceptors.request.use(
//   function(config) {
//     // Do something before request is sent
//     return config;
//   },
//   function(error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// _axios.interceptors.response.use(
//   function(response) {
//     // Do something with response data
//     return response;
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

let loading; //定义loading变量

function startLoading() {
  //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: "加载中...",
    background: "rgba(0, 0, 0, 0.7)"
  });
}
function endLoading() {
  //使用Element loading-close 方法
  loading.close();
}

// 请求拦截  设置统一header
_axios.interceptors.request.use(
  config => {
    // 加载
    startLoading();
    if (localStorage.eleToken)
      config.headers.Authorization = localStorage.eleToken;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截  401 token过期处理
_axios.interceptors.response.use(
  response => {
    endLoading();

    if(!response.data.status){
      if(response.data.code == '-2'){
        Message.error("未登录或登录过期");
        router.push("/login");
      }
    }

    return response.data;
  },
  error => {
    // 错误提醒
    endLoading();

    try{
      const { status } = error.response;
      if(status!=200){
        Message.error("网络请求错误，请联系管理员");
      }else{
        if(error.response){
          Message.error(error.response.data);
        }else{
          Message.error("未知错误");
        }
      }

    }catch(err){
      Message.error(err);
    }

    
    // if (status == 401) {
    //   Message.error("token值无效，请重新登录");
    //   // 清除token
    //   localStorage.removeItem("eleToken");

    //   // 页面跳转
    //   router.push("/login");
    // }

    return Promise.reject(error);
  }
);



Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
