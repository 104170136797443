// let server = "http://localhost:8080";
let server = "";
let kit =  {
    server: server,
    api:{
        companyInfo: server+"/api/p/companyInfo",
        login: server+"/am/account/login",  //登录
        reg: server+"/am/account/reg",  //注册
        logout: server+"/am/account/quitLogin",  //注销
        code: server+"/api/p/code",         //验证码
        linkProduct:server+"/api/p/linkProduct",
        rolemenu: server+"/am/menu/rolemenu", //菜单

        accountView: server+"/am/account/view", //账号详情
        accountList: server+"/am/account/list", //账号列表
        accountUpdate: server+"/am/account/update",//更新账号
        accountCreate: server+"/am/account/create",//创建账号
        accountdisable: server+"/am/account/updatedisabled", //启用禁用账号
        accountChangePwd: server + '/am/account/changePwd',
        accountRunAs: server + '/am/account/runAs', //登录该账号
        getUserSet: server + '/am/account/getUserSet',
        
        docUrl: server+"/document/",

        roleList: server+"/am/role/list", //角色列表
        roleUpdate: server+"/am/role/update",   //更新角色
        roleCreate: server+"/am/role/create",   //创建角色
        roleDelete:server+"/am/role/deletes",   //删除角色
        roleGrantPermission:server+"/am/role/grantPermission",  //给角色赋权
        rolePermission:server+"/am/role/rolePermission",    //获取角色权限
        roleMenuUpdate:server+"/am/role/roleMenuUpdate",    //给菜单赋权
        toggledMenu:server+"/am/role/toggledMenu",  //获取菜单，同时给选中已指定的角色
        permList: server+"/am/perm/list",   //查看权限
        permCreate: server+"/am/perm/create",   //新建权限
        permUpdate: server+"/am/perm/update",   //更新权限
        permDelete: server+"/am/perm/delete",   //删除权限

        menuList: server+"/am/menu/list",
        menuDelete: server+"/am/menu/deletes",
        menuCreate: server+"/am/menu/create",
        menuUpdate: server+"/am/menu/update",
		
        //国际化
        languageList:server+"/am/i18n/lanList",
        languageCreate:server+"/am/i18n/lanCreate",
        languageUpdata:server+"/am/i18n/lanUpdate",
        languageDelete:server+"/am/i18n/lanDelete",

        langs: server+"/api/p/langs",
        resources: server+"/api/p/resources",
        addResources: server+"/api/p/addResources",

        attachmentList: server+"/am/attachment/list", //图片管理
        attachmentDelete: server+"/am/attachment/deletes",
        attachmentView: server+"/am/attachment/img",
        upload: server+"/am/attachment/upload",
        pdf: server+"/am/attachment/pdf",
        imgView: server+"/api/p/img?id=",
        
        settingRoot: server+"/am/setting/root",
        settingList: server+"/am/setting/list",
        settingCreate: server+"/am/setting/create",
        settingUpdate: server+"/am/setting/update",
        settingDelete: server+"/am/setting/delete",  
              
        resourceList:server+"/am/i18n/resList",
        resourceUpdata:server+"/am/i18n/resUpdate",
        resourceCreate:server+"/am/i18n/resCreate",
        getRes:server+"/am/i18n/gatResources",

        histList:server+"/am/hist/list",
        histUpdate:server+"/am/hist/update",
        histCreate:server+"/am/hist/create",
        histDelete:server+"/am/hist/deletes",

        //订单
        orderList: server+"/am/order/list",
        orderListByExp: server+"/am/order/orderListByExp",
        orderCreate: server+"/am/order/create",
        createForTicket: server+"/am/order/createForTicket",
        orderUpdate: server+"/am/order/update",
        orderDelete: server+"/am/order/delete",
        expResend:server+"/am/order/resend",
        orderCodeDownload: server+"/static/tpl/order-shelf-code.xlsx",
        codeCheckDownload: server+"/static/tpl/code-check.xlsx",
        expStoreDownload: server+"/static/tpl/exp-store.xlsx",
        expStoreDownloadTw: server+"/static/tpl/exp-store-tw.xlsx",
        orderCodeImport: server+"/am/order/codeImport",
        orderUpdateShelfCode:server+"/am/order/updateShelfCode",
        expUpper:server+"/am/order/expUpper",

        //物流信息
        expList: server+"/am/exp/list",
        expireExpList: server+"/am/expireExpress/list",
        pagePicExport: server+"/am/expireExpress/pagePicExport",
        expListForTicket: server+"/am/exp/listForTicket1",
        expDelete: server+"/am/exp/delete",
        expCreate: server+"/am/exp/create",
        expUpdate: server+"/am/exp/update",
        expUpdateRemark: server+"/am/exp/updateRemark",
        expDetail: server+"/am/exp/view",
        expUploadPdfs: server+"/am/exp/uploadPdfs",
        expOnlyUploadPdfs: server+"/am/exp/onlyUploadPdfs",
        expPrintTicket: server+"/am/exp/printTicket",
        expPkgPrintTicket: server+"/am/exp/pkgAndPrintTicket",
        expPrintPage: server+"/am/exp/printPage",
        expUpdatePkg: server+"/am/exp/updatePkg",
        expUpdateDownload: server+"/static/tpl/exp-update.xlsx",
        expPaymentDownload: server+"/static/tpl/exp-payment.xlsx",
        expUpdateCreated: server+"/am/exp/updateCreated", //将线下的更新为线下已建单
        expImportTwExpCode: server+"/am/exp/importTwExpCode", //导入台湾的物流码
        exportExp: server+"/am/exp/exportExp", //下载建单资料
        downloadExpress: server+"/am/exp/downloadExpress", //下载物流列表
        expUpdateField: server+"/am/exp/updateFields", //批量更新指定的字段
        expUpdatePageStatus: server+"/am/exp/updatePageStatus", //物流信息-通知上传面单
        expLineCreate:server+"/am/exp/createUnderline",
        expCheckStatus:server+"/am/exp/expCheckStatus",
        updateNote:server+"/am/exp/updateNote",

        traces:server+"/am/exp/traces",

        checkStatusHistList:server+"/am/checkHist/list",
        sendHistList:server+"/am/sendHist/list",
        searchExpCodeByPkgCode:server+"/am/sendHist/searchExpCodeByPkgCode",

        codeCheckList:server+"/am/codeCheck/list",
        codeCheckHistList:server+"/am/codeCheckHist/list",
        codeCheckImport:server+"/am/codeCheck/codeCheckImport",
        codeCheckSearch:server+"/am/codeCheck/codeCheckSearch",
        codeCheckEdit:server+"/am/codeCheck/codeCheckEdit",

        //台湾库存
        expStoreList:server+"/am/expStore/list",
        expStoreImport:server+"/am/expStore/expStoreImport",
        expStoreImportTw:server+"/am/expStore/expStoreImportTw",
        expStoreUpper:server+"/am/expStore/expStoreUpper",
        expStoreSearch:server+"/am/expStore/searchStore",
        expStoreMatchList:server+"/am/expStore/matchList",
        expStoreDelete:server+"/am/expStore/delete",
        matchSave:server+"/am/expStore/matchSave",
        matchCancel:server+"/am/expStore/cancel",
        downloadExpStoreMatch:server+"/am/expStore/downloadExpStoreMatch",

        savePkgCode:server+"/am/exp/savePkgCode",

        batchSendScan:server+"/am/exp/batchSendScan",
        batchSendScans:server+"/am/exp/batchSendScans",
        scanSaveCode:server+"/am/exp/scanSaveCode",

        expImportUpdate: server+"/am/exp/updateImport", //导入更新
        
        expPrintPkg: server+"/am/print/pkgAndPrint", //打包打印
        expPrintGet: server+"/am/print/print", //获取打印数据
        expPrintFind: server+"/am/print/finds", //获取找货单数据

        expWeightImport:server+"/am/exp/expWeightImport",
        expOrderStatusImport:server+"/am/exp/expOrderStatusImport",

        expCancel:server+"/am/exp/cancel",
        expTimeOut:server+"/am/exp/expTimeOut",
        expInnerPrintPage: server+"/am/exp/expInnerPrintPage",

        updateStorePkg:server+"/am/exp/updateStorePkg",
        exportListForTicket:server+"/am/exp/exportListForTicket",

        //导入物流信息
        expImport: server+"/am/exp-in/doImport",
        doTplImport: server+"/am/exp-in/doTplImport",
        tplDownload: server+"/static/tpl/exp-tpl.xlsx",
        tplStoreDownload: server+"/static/tpl/exp-store-tpl.xlsx",
        
        //物流装袋信息
        expPkgList: server+"/am/exp-pkg/list",
        expPkgDetail: server+"/am/exp-pkg/detail",
        expPkgCreate: server+"/am/exp-pkg/create",
        expPkgUpdate: server+"/am/exp-pkg/update",
        expPkgDelete: server+"/am/exp-pkg/delete",
        expPkgStatus: server+"/am/exp-pkg/updateStatus",
        expPkgNotify: server+"/am/exp-pkg/notifyUpPage",
        expPkgDownload: server+"/am/exp-pkg/export",
        expPkgIptUpdate: server+"/am/exp-pkg/iptUpdate",
        tplPkgDownload: server+"/static/tpl/exp-pkg-tpl.xlsx",
        expPkgWarnMsg: server+"/am/exp-pkg/warnMsg",
        expPkgImportPkgs: server+"/am/exp-pkg/importPkg",
        expPkgsTpl: server+"/static/tpl/exp-pkgs-tpl.xlsx",
        expImportPkgWithEditor: server+"/am/exp-pkg/importPkgWithEditor", //资料员导入包号
        expImportPayment:server+"/am/payment/expImportPayment", //导入新字段

        //报警消息
        warnTotal: server+"/am/exp/warnTotal",        
        warnList: server+"/am/exp/warnList",   
        expUploadErrPdf: server+"/am/exp/uploadErrPdf",     
        
        //商品名
        simpleProductNameList: server+"/am/simple-product-name/list",
        simpleProductNameCreate: server+"/am/simple-product-name/create",
        simpleProductNameUpdate: server+"/am/simple-product-name/update",
        simpleProductNameDelete: server+"/am/simple-product-name/deletes",
        exportOk: server+"/am/simple-product-name/exportOk",
        
		//异常面单 
        expErrorMessageList: server+"/am/expErrorMessage/list",
        expErrorMessageListAll: server+"/am/expErrorMessage/getAlllist",
        expErrorMessageCreate: server+"/am/expErrorMessage/create",
        expErrorMessageUpdate: server+"/am/expErrorMessage/update",
        expErrorMessageDelete: server+"/am/expErrorMessage/deletes", 
        
        //上架模板      
        expShelfBackDownload: server+"/static/tpl/exp-back-shelf-code-tpl.xlsx", 
        //仓位号模板   
        storeCodeDownload: server+"/static/tpl/store-code-tpl.xlsx",   
        comTest: server+"/static/COMTest.zip",
	
        //回寄物流信息
        expBackList: server+"/am/expBack/list",
        expBackCreate: server+"/am/expBack/create",
        expBackUpdate: server+"/am/expBack/update",
        expBackDelete: server+"/am/expBack/delete", 
        expBackPrintSubPkg: server+"/am/expBack/printSubPkg", 
        importExpBack: server+"/am/expBack/importExpBack",
        expBackDownload: server+"/static/tpl/exp-back.xlsx",
        expBackTableDownload:server+"/am/expBack/downloadExpBack",
        expShelfTableDownload:server+"/am/exp-shelf/downloadShelfBack",

        twStoreErr:{
            list: server+"/am/twStoreError/list",
            upper: server+"/am/twStoreError/upper",
            delete: server+"/am/twStoreError/delete",
            update: server+"/am/twStoreError/update",
            updateShelfCode: server+"/am/twStoreError/updateShelfCode",
            resendCode: server+"/am/twStoreError/resendCode",
        },

		twStore:{
            resendExps: server+"/am/tw-store/resendExps",  //转出重寄的新物流信息
            details: server+"/am/tw-store/details",   //获取仓库详情信息
            resend: server+"/am/tw-store/resend",   //执行转寄操作
            list: server+"/am/tw-store/list",
            uppers:server+"/am/tw-store/upper",
            updates:server+"/am/tw-store/uppers",
            updateExps:server+"/am/tw-store/updateExps",
            delete:server+"/am/tw-store/delete",
            view:server+"/am/tw-store/get",
            sendBack:server+"/am/tw-store/sendBack",
            printTicket:server+"/am/tw-store/printTicket",
            updatesStoreCode:server+"/am/tw-store/storeCodeUppers",//更新仓位号
            cancel:server+"/am/tw-store/cancel",
            addDay:server+"/am/tw-store/addDay",
        },
        storeBackDownload: server+"/static/tpl/store-back-tpl.xlsx",//仓储退件基础模板
        storeBackDownloadInner: server+"/static/tpl/store-back-inner-tpl.xlsx",//仓储退件资料员导入模板
        resend:{
            tickets: server + "/am/expResend/tickets",
            finderTickets: server + "/am/expResend/finderTickets",
            autoResend: server + "/am/tw-store/autoResend",
            detail: server + "/am/expResend/detail",
        },
        //转寄记录
        expResendList: server+"/am/expResend/list",
        expResendDelete: server+"/am/expResend/delete", 
        expResendView: server+"/am/expResend/view",	
        expResendNotify: server+"/am/expResend/notifyUpPage",
        expResendNotifyStatus: server+"/am/expResend/expResendNotifyStatus",

        //下载驱动
        gprintDownload: server+"/static/tpl/gprinter_v7.zip",
        exeDownload: server+"/static/tpl/1574038174687.exe",

        shopList: server+"/am/shop/list", //店铺列表
        shopAdd: server+"/am/shop/add", //绑定店铺
        shopDel: server+"/am/shop/delete", //删除店铺
        updateShopName: server+"/am/shop/updateShopName",
        shopSync: server+"/am/shop/sync", //同步店铺
        shopSyncOrder: server+"/am/shop/syncOrder", //同步订单
        shopUpdateOwner: server+"/am/shop/shopUpdateOwner", //分配子账号
        syncToken: server+"/am/shop/syncToken",
        batchSend: server+"/am/shop/batchSend", //一键发货
        getShops:server+"/am/shop/getShops",

        getChildList:server+"/am/account/getChildList",
        
        balance:{
            get: server+"/am/balance/get",
            create: server+"/am/balance/create",
            update: server+"/am/balance/update",
        }
    },
    rebuildQuery: function(query){
        var param = {};
        for(var key in query){
            var value = query[key];
             
            if(value==null) {
                continue;
            }else if(value === ""){
                continue;
            }else if(this.isArray(value)){
                try{
                    if(value[0])
                        param["start"+key]=value[0];
                    if(value[1])
                        param["end"+key]=value[1];
                }catch(err){
                    console.error(err);
                }
            }else{
                param[key]=value;
            }
        }
        return param;
    },
    clearQuery: function(query){
        for(var key in query){
            if(key=='start') {
                query[key] = 0;
            }else if(key == 'length'){
                continue;
            }else{
                var val = query[key];
                if(this.isArray(val) && val.length>0){
                    query[key].splice(0, query[key].length);
                }else{
                    query[key] = null;
                }
            }
        }
    },
    isArray : function(arr){
        return Object.prototype.toString.call(arr) === '[object Array]';
    },
    uuuid: function(){
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
    
        var uuid = s.join("");
        return uuid.replace("-", "");
    },
    getColumns(key){
        var columns = {};
        try{
            var colsStr = localStorage.getItem(key);
            if(colsStr){
               columns = JSON.parse(colsStr);
            }else{
              columns['defaultShow'] = true;
            }
        }catch(err){
            columns['defaultShow'] = true;
            console.log(err);
        }
        return columns;
    },
    getQueryValue(queryName) {
        var query = decodeURI(window.location.search.substring(1));
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == queryName) { return pair[1]; }
        }
        return null;
    },
    icons: ['icon-suishiquchusvg',
        'icon-dayin',
        'icon-dabao',
        'icon-wuliu',
        'icon-piliangshangchuan',
        'icon-dayin1',
        'icon-caidan',
        'icon-tubiaosvg-',
        'icon-gonggao',
        'icon-shezhi2',
        'icon-shezhi',
        'icon-chakan-',
        'icon-shezhi1',
        'icon-xitong',
        'icon-yuyan',
        'icon-yemianxian',
        'icon-yuyan-ying',
        'icon-yuyan-zhong',
        'icon-lang-txt-zh-tw',
        'icon-zuhuhoutaiguanlitubiao_-',
        'icon-fenlei2-copy',
        'icon-zhanghu',
        'icon-quanxian',
        'icon-shezhi3',
        'icon-vip',
        'icon-tupian',
        'icon-jiaose',
        'icon-xiewenzhang-copy',
        'icon-fenlei',
        'icon-guolvqi'
    ],
   
};

kit.install = function(Vue, options) {
    Vue.kit = kit;
    window.kit = kit;
    Object.defineProperties(Vue.prototype, {
      kit: {
        get() {
          return kit;
        }
      },
      $kit: {
        get() {
          return kit;
        }
      },
    });
  };

export default kit;
