<template>
    <div class="table-wrap" style="min-height:300px">
      <div v-if="warns && warns.length>0" style="display: flex;flex-direction:row;">
        <h2 style="font-size:14px; font-weight:bold;margin-bottom:20px">{{msg('通知上传面单')}}</h2>
        <div style="text-align:right;width: 85%;">
          <el-button type="warning" style="margin-top:10px;" size="mini" @click="goUpload">{{msg('去上传')}}</el-button>
        </div>
      </div>
        <el-table v-if="warns && warns.length>0"  :data="warns" style="width: 100%" size="mini" ref="dataTable1" max-height="300" stripe>
          <el-table-column label="#" fixed>
            <template slot-scope="scope">
              <el-button type="success" plain size="mini" @click="addToLocal(scope.row, 'locExpWarns')">{{msg('知道了')}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="orderCode" :label="msg('订单编号')" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="expCompany" :label="msg('物流厂商')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="goodName" :label="msg('品名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column :label="msg('允许上传面单')" min-width="110"
           prop="allowUploadPage">
            <template slot-scope="scope">
              <span>
                <el-button v-if="scope.row.allowUploadPage===0" type="warning"  size="mini">{{msg('未允许')}}</el-button>
                <el-tag v-else type="success" disable-transitions>{{msg('已允许')}}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column  min-width="100"
           :label="msg('面单状态')" prop="pagePrintStatus" 
            >
            <template slot-scope="scope">
              <el-tag :type="'success'" v-if="scope.row.pagePrintStatus == 0" disable-transitions>{{msg('未上传')}}</el-tag>
              <el-tag :type="'success'" v-else disable-transitions @click="openPdfPage(scope.row.expPageId)">{{scope.row.pagePrintStatus == 1 ? msg("待打印"):msg("已打印")}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="msg('物流状态')" min-width="100"
           prop="status">
            <template slot-scope="scope">
              <el-tag type="warning" disable-transitions>{{dicts.expStatus[scope.row.status]}}</el-tag>
            </template>
          </el-table-column>
        </el-table>

        <h2 v-if="expErrs && expErrs.length > 0" style="font-size:14px; font-weight:bold; margin-bottom:20px;">{{msg('错误面单')}}</h2>
        <el-table v-if="expErrs && expErrs.length > 0" :data="expErrs" style="width: 100%" size="mini" ref="dataTable2" max-height="300" stripe>
          <el-table-column label="#" fixed>
            <template slot-scope="scope">
              <el-button v-if="scope.row.errType==0" type="success" plain size="mini" @click="addToLocal(scope.row, 'locErrExps')">{{msg('知道了')}}</el-button>
              <el-button v-else type="success" plain size="mini" @click="updateResolve(scope.row)">{{msg('知道了')}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="orderCode" :label="msg('订单编号')" min-width="100" :show-overflow-tooltip="true"></el-table-column>
           <el-table-column :label="msg('错误类型')" min-width="110"
           prop="errType">
            <template slot-scope="scope">
              <el-tag :type="dicts.cssTypes[scope.row.errType]" disable-transitions>
                {{dicts.expErrTypes[scope.row.errType]}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="expCompany" :label="msg('物流厂商')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="goodName" :label="msg('品名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column :label="msg('允许上传面单')" min-width="110"
           prop="allowUploadPage">
            <template slot-scope="scope">
              <span>
                <el-tag :type="dicts.cssTypes[scope.row.allowUploadPage]" disable-transitions>
                  {{dicts.allowUploadPages[scope.row.allowUploadPage]}}
                </el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column  min-width="100"
           :label="msg('面单状态')" prop="pagePrintStatus" 
            >
            <template slot-scope="scope">
              <el-tag :type="'success'" v-if="scope.row.pagePrintStatus == 0" disable-transitions>{{msg('未上传')}}</el-tag>
              <el-tag :type="'success'" v-else disable-transitions @click="openPdfPage(scope.row.expPageId)">{{scope.row.pagePrintStatus == 1 ? msg("待打印"):msg("已打印")}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="msg('物流状态')" min-width="100"
           prop="status">
            <template slot-scope="scope">
              <el-tag type="warning" disable-transitions>{{dicts.expStatus[scope.row.status]}}</el-tag>
            </template>
          </el-table-column>
            <el-table-column prop="" :label="msg('操作')" min-width="100" >
              <template slot-scope="scope">
                <el-upload v-if="scope.row.errType==0" class="upload-demo" :with-credentials="true" multiple
                    :file-list="fileList"
                    :action="uploadPdf+'?id='+scope.row.id+'&expId='+scope.row.expId"
                    :on-success="handleSuccess"
                    >
                      <el-button size="mini" icon="el-icon-upload2" type="success" round>{{msg('上传面单')}}</el-button>
                </el-upload>
              </template>
            </el-table-column>
        </el-table>

        <h2 v-if="timeOuts && timeOuts.length > 0" style="font-size:14px; font-weight:bold; margin-bottom:20px;">{{msg('退件上架过期提醒')}}</h2>
        <el-table v-if="timeOuts && timeOuts.length > 0" :data="timeOuts" style="width: 100%" size="mini" ref="dataTable3" max-height="300" stripe>
          <el-table-column label="#" fixed>
            <template slot-scope="scope">
              <el-button type="success" plain size="mini" @click="addToLocal(scope.row, 'locTimeouts')">{{msg('知道了')}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="orderCode" :label="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="saveExpire" :label="msg('过期时间')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="shelfCode" :label="msg('货架号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
        </el-table>

        <el-dialog :title="msg('批量导入面单')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen"  width="600px" 
            @opened="hendleOpenedImport()" append-to-body>
          <exp-page-import ref="expPageImport"/>
        </el-dialog> 

    </div>
    
</template>

<script>
import ExpPageImport from "../business/ExpPageImport.vue";
export default {
  name: 'WarnMessage',
  components: {
    ExpPageImport: ExpPageImport,
  },
  data(){
    return{
       uploadPdf: this.$kit.api.expUploadErrPdf,
       statuses: this.$kit.expStatuses,
       dialogImportVisible: false,
       fileList: [],
    }
  }, 
  props: {
    warns: Array,
    expErrs: Array,
    timeOuts: Array,
  },
  computed:{
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  mounted: function(){
  },
  methods:{
      /*
      init(){
        this.getMessageData();
      },
      getMessageData(){
        var _this = this;
        this.axios.get(this.$kit.api.warnList).then(function (result) {
              if(result.status){
                var warns = result.data["exps"];
                var errs = result.data["expErrs"];
                var timeOuts = result.data['timeouts'];
                if(warns){
                  _this.exps = warns;
                }
                if(errs){
                  _this.expErrs = errs;
                }
                if(timeOuts){
                  _this.timeOuts = timeOuts;
                }
              }
          });
      },
       */
      goUpload(){
        this.dialogImportVisible = true;
      },
      hendleOpenedImport(){
        this.$nextTick(() => {
          this.$refs.expPageImport.initForm();
        });
      
    },
   
    handleSuccess(res, file, fileList) {
      var _this = this;
        if(res.status){
            this.$message(res.data.msg || this.msg("上传成功"));
            if(res.data.msg != "保存成功"){
                _this.fileList = [];
            }
        }else{
          this.$message(res.data.msg || this.msg("上传失败"));
        }
    },
    addToLocal(exp, type){
      var exps = localStorage.getItem(type);
      if(!exps){
        localStorage.setItem(type, JSON.stringify([exp]));
      }else{
        let temp = JSON.parse(exps);
        if(!temp.find(t => t.id == exp.id)){
          temp.push(exp);
          localStorage.setItem(type, JSON.stringify(temp));
        }
        this.$message(this.msg("标记成功"));
      }
    },
    updateResolve(err){
      this.axios.post(this.$kit.api.expErrorMessageUpdate, {id: err.id, resolve: 1}).then( (result) => {
          this.$message(result.msg);
      });
    }
  }
}
</script>
