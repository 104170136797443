import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path:'/admin',
    name: 'home',
    component: Home,
    redirect: '/admin/exp', 
    children:[
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/sys/Account.vue')
      },
      {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '../views/sys/Role.vue')
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "role" */ '../views/sys/Menu.vue')
      },
      {
        path: 'attachment',
        name: 'attachment',
        component: () => import(/* webpackChunkName: "attachment" */ '../views/sys/Attachment.vue')
      },
      {
        path: 'permission',
        name: 'permission',
        component: () => import(/* webpackChunkName: "permission" */ '../views/sys/Permission.vue')
      },
		{
        path: 'i18n',
        name: 'i18n',
        component: () => import(/* webpackChunkName: "i18n" */ '../views/sys/I18n.vue')
      },
		{
        path: 'setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/sys/Setting.vue')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "notice" */ '../views/sys/Notice.vue')
      },
      {
        path: 'hist',
        name: 'hist',
        component: () => import(/* webpackChunkName: "hist" */ '../views/sys/Hist.vue')
      },
      {
        path: 'order',
        name: 'order',
        component: () => import(/* webpackChunkName: "order" */ '../views/business/Order.vue')
      },
      {
        path: 'exp-pkg',
        name: 'exp-pkg',
        component: () => import(/* webpackChunkName: "expPkg" */ '../views/business/ExpPkg.vue')
      },
      {
        path: 'exp-import',
        name: 'exp-import',
        component: () => import(/* webpackChunkName: "expImport" */ '../views/business/ExpImport.vue')
      },
      {
        path: 'exp',
        name: 'exp',
        component: () => import(/* webpackChunkName: "exp" */ '../views/business/Express.vue')
      },
      {
        path: 'expireExp',
        name: 'expireExp',
        component: () => import(/* webpackChunkName: "exp" */ '../views/business/ExpireExpress.vue')
      },
      {
        path: 'exp-print-ticket',
        name: 'exp-print-ticket',
        component: () => import(/* webpackChunkName: "expPrintTicket" */ '../views/business/ExpPrintTicket.vue')
      },
      {
        path: 'exp-print-page',
        name: 'exp-print-page',
        component: () => import(/* webpackChunkName: "expPrintPage" */ '../views/business/ExpPrintPage.vue')
      },
      {
        path: 'exp-inner-print-page',
        name: 'exp-inner-print-page',
        component: () => import(/* webpackChunkName: "expPrintPage" */ '../views/business/ExpInnerPrintPage.vue')
      },
      {
        path: 'simple-product-name',
        name: 'simple-product-name',
        component: () => import(/* webpackChunkName: "simpleProductName" */ '../views/business/SimpleProductName.vue')
      },
      {
          path: 'exp-error-message',
          name: 'exp-error-message',
          component: () => import(/* webpackChunkName: "expErrorMessage" */ '../views/business/ExpErrorMessage.vue')
      },
      {
        path: 'exp-back',
        name: 'exp-back',
        component: () => import(/* webpackChunkName: "ExpBack" */ '../views/business/store/ExpBack.vue')
      },
      {
        path: 'store',
        name: 'store',
        component: () => import(/* webpackChunkName: "store" */ '../views/business/store/Store.vue')
      },
      {
        path: 'exp-resend',
        name: 'exp-resend',
        component: () => import(/* webpackChunkName: "exp-resend" */ '../views/business/store/ExpResend.vue')
      },
      {
        path: 'shop',
        name: 'shop',
        component: () => import(/* webpackChunkName: "shop" */ '../views/business/Shop.vue')
      },
      {
        path: 'document',
        name: 'document',
        component: () => import(/* webpackChunkName: "document" */ '../views/sys/Document.vue')
      },
      {
        path: 'store-err',
        name: 'store-err',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/store/StoreError.vue')
      },
      {
        path: 'upper',
        name: 'upper',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/store/ExpUpper.vue')
      },
      {
        path: 'check',
        name: 'check',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/CheckStatus.vue')
      },
      {
        path: 'checkStatusHist',
        name: 'checkStatusHist',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/CheckStatusHist.vue')
      },
      {
        path: 'codeCheck',
        name: 'codeCheck',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/CodeCheck.vue')
      },
      {
        path: 'expStore',
        name: 'expStore',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/ExpStore.vue')
      },
      {
        path: 'expStoreMatch',
        name: 'expStoreMatch',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/ExpStoreMatch.vue')
      },
      {
        path: 'expStoreUpper',
        name: 'expStoreUpper',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/ExpStoreUpper.vue')
      },
      {
        path: 'codeCheckHist',
        name: 'codeCheckHist',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/CodeCheckHist.vue')
      },
      {
        path: 'codeCheckScan',
        name: 'codeCheckScan',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/CodeCheckScan.vue')
      },
      {
        path: 'scan',
        name: 'scan',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/BatchSendScan.vue')
      },
      {
        path: 'scanHist',
        name: 'scanHist',
        component: () => import(/* webpackChunkName: "store-err" */ '../views/business/BatchSendScanHist.vue')
      },
      {        
        path:'*',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
      }
    ]
  },
  { 
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  { 
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "Reg" */ '../views/Reg.vue')
  },
  {
    path:'*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
/*
router.beforeEach((to, from, next) => {
  console.log(to, from , next);
  if (from.name === 'specGroup') {
    next(vm => {
      debugger;
      console.log("12312");
      vm.$route.meta.keepAlive = false
    });
  }else{
    next()
  }
})
*/

export default router
