import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMinScreen: false,
    docHeight: 300,
    isQueryExpend: true,
    companyInfo: null
  },
  mutations: {
    isMinScreen (state, val) {
      state.isMinScreen = val; 
    },
    docHeight (state, val) {
      state.docHeight = val; 
    },
    isQueryExpend (state, val) {
      state.isQueryExpend = val; 
    }, 
    companyInfo(state, val){
      state.companyInfo = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
