<template>
 <el-form ref="form" :model="pwd" label-width="100px">
    <el-row>
        <el-col :xs="24">
            <el-form-item :label="msg('密码')">
                <el-input v-model="pwd.password" type="password"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24">
            <el-form-item :label="msg('确认密码')">
                <el-input v-model="pwd.repassword" type="password"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <div style="text-align:center; margin: 15px 0px;">
        <el-button type="primary" size="mini"  @click="doChangePwd()"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form>
</template>

<script>
export default {
  name: 'AccountPwd',
  data(){
    return{
        pwd:{
            password: null,
            repassword: null,
        },
    }
    
  }, 
  methods:{
    initForm(accountId){
        this.pwd.password = null;
        this.pwd.repassword = null;
    },
   doChangePwd(){
      if(!this.pwd.password){
        this.$message(this.msg('密码不能为空'));
        return;
      }

      if(this.pwd.password != this.pwd.repassword){
        this.$message(this.msg('两次输入的密码不一致'));
        return;
      }

      this.$axios.post(this.$kit.api.accountChangePwd, this.pwd)
        .then((result) => {
          
          if(result.status){
            this.$emit('closeDg');
          }

          this.$message(result.msg);
        }).catch(function (error) {
          console.error(error);
        });

    }
  }
}
</script>

<style class="scss" scoped>


</style>
