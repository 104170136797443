import kit from './kit.js';


var I18N = function(){
    var langs = null;
    var langStr = null;
    var lang = null;


    let getAjax = function(url){
        var xhr;
        if(window.XMLHttpRequest){
            xhr = new XMLHttpRequest();
        }else{
            xhr = new window.ActiveXObject('Microsoft.XMLHTTP');
        }
        
        xhr.open('get', url,false);
        xhr.setRequestHeader('X-Requested-With','XMLHttpRequest')
        xhr.send();
        let result = null;
        if(xhr.readyState == 4){
            if(xhr.status == 200){
                try{
                    //实际操作
                    result = xhr.responseText;

                    result = JSON.parse(result);
                }catch(err){
                    console.error(err);
                }
            }
        }
        return result;
    }

    let result = getAjax(kit.api.langs);

    if(result.status){
        langs = {};
        result.data.forEach(m => langs[m.name]=m);
    }else{
        console.error("获取语言列表",result.msg);
    }
    
    let getLang = function(){
        langStr = localStorage.getItem("lang");
        if(!langStr){
            langStr = 'zh-cn';
            localStorage.setItem("lang", langStr);
        }
        lang = langs[langStr];
    }
    getLang();

    let msg = function(key){

        if(!key) return key;
        getLang();

        if(!lang["resources"] || lang["resources"].length==0){
            let result = getAjax(kit.api.resources+"?id="+lang.id);
            if(result.status){
                lang["resources"] = result.data;
            }else{
                console.error("获取资源内容", result.msg);
            }
        }

        let val = lang["resources"][key];
        if(!val){
            var xhr;
            if(window.XMLHttpRequest){
                xhr = new XMLHttpRequest();
            }else{
                xhr = new window.ActiveXObject('Microsoft.XMLHTTP');
            }
            
            xhr.onreadystatechange = function(){
                if(xhr.readyState == 4){
                    if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
                        try{
                            let result = xhr.responseText;
                            result = JSON.parse(result);
                            console.log(result.msg);
                        }catch(err){
                            console.error("添加语言失败", xhr.status);
                        }
                    }else{
                        console.error("添加语言失败：", xhr.status);
                    }
                }
            }

            xhr.open("POST",  kit.api.addResources ,true);
            xhr.setRequestHeader('Content-Type','application/json; charset=utf-8');
            xhr.setRequestHeader('X-Requested-With','XMLHttpRequest');
            xhr.send(JSON.stringify({languageId: lang.id, name: key, msg: key}));

            lang["resources"][key] = key;
            return key;

        }
        return val;
    }
    return {
        msg : msg,
        lang: lang,
        langs: langs,
        langStr: langStr
    }
}

var i18n = I18N();
var i18nKit = {
    data(){
      return{};
    },
    methods:{
      msg(key){
        return i18n.msg(key);
      }, 
      fmt(key, vals){
        var msg = i18n.msg(key);
        if(msg){
            for(let i=0; i<vals.length; i++){
                msg = msg.replace("%s",vals[i]);
            }
        }
        return msg;
      }
    }
}

export default i18nKit;
